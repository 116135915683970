import React from "react";
import {Box,Heading, ListItem, List} from '@chakra-ui/react'
import Link from '../components/gatsbylink'
import MySeo from '../components/seo'
import {motion} from 'framer-motion'


export default function Beteiligte(){
    return(
        <motion.div
        initial={{opacity:0,scale:.8,paddingBottom:"100px"}}
        animate={{opacity:1,scale:1 }}
        transition={{duration:.8,delay:.1}}
        >
            <MySeo title="Projektbeteiligte Verluste. Parcours 2022 in Bacharach und Oberwesel" description="Liste der Beteiligten"/>
           
            <Box pl={["2","4","6"]}>
                <Heading  letterSpacing=".12rem" variant="Titel" color="text">
                    Beteiligte
                </Heading>
               <Heading variant="btitel">Beiträge in alphabetischer Reihenfolge

                </Heading>
                 <List pt="3" pb="4">
                     <ListItem>
                      René Broich, K.O.M
                     </ListItem>
                     <ListItem>
                        Ferdinand Fries
                     </ListItem>
                     <ListItem>
                         Katrin Gloggengiesser, K.O.M
                     </ListItem>
                     <ListItem>
                         Karl-Martin Hartmann, Künstler
                     </ListItem>
                     <ListItem>
                         Dr. Walter Karbach, Historiker
                     </ListItem>
                     <ListItem>
                         Carolin Manns, Hunsrück-Museum Simmern
                     </ListItem>
                     <ListItem>
                        Christoph Pies, Hunsrück-Museum Simmern
                     </ListItem>
                 </List>
                
                
                <Heading variant="btitel">
                     Organisation / Redaktion
                 </Heading>
                 <List pt="3" pb="4">
                     <ListItem>Dr. Dagmar Aversano-Schreiber, Historikerin</ListItem>
                     <ListItem>
                      René Broich, K.O.M
                     </ListItem>
                     <ListItem>
                        Prof. Dr. Susanne Enderwitz, K.O.M
                     </ListItem>
                     <ListItem>
                         Katrin Gloggengiesser, K.O.M
                    </ListItem>
                    <ListItem>Werner Heinz</ListItem>
                    <ListItem>
                        Dr. Walter Karbach
                    </ListItem>
                    <ListItem>
                        Carolin Manns, Hunsrück-Museum Simmern
                    </ListItem>
                    <ListItem>
                        Christoph Pies, Hunsrück-Museum Simmern
                    </ListItem>
                    <ListItem>
                         Sarah Piller, Zweckverband Welterbe Oberes Mittelrheintal
                     </ListItem>
                    <ListItem>
                        Doris Spormann
                    </ListItem>
                    <ListItem>
                         Fritz Stüber, K.O.M &amp; Verschönerungsverein Bacharach
                    </ListItem>
                 </List>
                <Heading variant="btitel">
                     Layout / Satz
                 </Heading>
                 <List pt="3" pb="4">
                     <ListItem>
                       Katrin Gloggengiesser, K.O.M
                     </ListItem>
                     <ListItem>
                         Gernot Kallweit (keyvisual, Anzeige)
                    </ListItem>
                    
                 </List>
                <Heading variant="btitel">
                     Programmierung
                 </Heading>
                 <List pt="3" pb="4">
                     <ListItem>
                         René Broich, K.O.M
                     </ListItem>
                 </List>
                <Heading variant="btitel">
                     Druck
                 </Heading>
                 <List pt="3" pb="4">
                     <ListItem>
                         www.wir-machen-druck.de<br/>
                         klimaneutral gedruckt
                     </ListItem>
                 </List>
                <Heading variant="btitel">
                     Veranstalter 
                 </Heading>
                 <List pt="3" pb="4">
                 <ListItem fontWeight="700">
                   K.O.M Kulturnetz Oberes Mittelrheintal e.V.
                 </ListItem>
                 
                  <ListItem>
                    Katrin Gloggengiesser, K.O.M (1. Vorsitzende)
                  </ListItem>
                  <ListItem>
                    Prof. Dr. Susanne Enderwitz, K.O.M (2. Vorsitzende)
                  </ListItem>
                  <ListItem>
                      <Link href="https://www.kulturnetz-oberes-mittelrheintal.org">www.kulturnetz-oberes-mittelrheintal.org</Link>
                  </ListItem>
                 </List>
            </Box>
           
        </motion.div>
        
    )
}